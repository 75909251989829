// src/data/blogData.js

import Blog_1 from "../blogs/Blog_1";
import Blog_2 from "../blogs/Blog_2";
import Blog_3 from "../blogs/Blog_3";
import Blog_4 from "../blogs/Blog_4";
import Blog_5 from "../blogs/Blog_5";
import Blog_6 from "../blogs/Blog_6";
import Blog_7 from "../blogs/Blog_7";
const siteUrl = "https://firekirin777slots.com";
let blogItems = [
  {
    id: "1",
    slug: "tips-to-win-big-on-online-sweepstakes-slots",
    image: "/assets/carousel/sweepstakes-slots.png",

    metaTitle: "Tips to Win Big on Online Sweepstakes Slots",
    title: "Tips to Win Big on Online Sweepstakes Slots",
    content:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    metaDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    canonical: siteUrl + "/blog/tips-to-win-big-on-online-sweepstakes-slots",

    ogTitle: "Tips to Win Big on Online Sweepstakes Slots",
    ogDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    ogImage: siteUrl + "/assets/carousel/sweepstakes-slots.png",
    component: Blog_1,
    sharingUrl: siteUrl + "/blog/tips-to-win-big-on-online-sweepstakes-slots",
    date: "2024-11-25",
  },
  {
    id: "2",
    slug: "your-ultimate-online-sweepstakes-casino-experience",
    image: "/assets/carousel/casino.jpg",

    metaTitle:
      "Discover the Thrills of FireKirin: Your Ultimate Online Sweepstakes Casino Experience",
    title:
      "Discover the Thrills of FireKirin: Your Ultimate Online Sweepstakes Casino Experience",
    content:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    metaDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    canonical:
      siteUrl + "/blog/your-ultimate-online-sweepstakes-casino-experience",

    ogTitle:
      "Discover the Thrills of FireKirin: Your Ultimate Online Sweepstakes Casino Experience",
    ogDescription:
      "Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!",
    ogImage: siteUrl + "/assets/carousel/casino.jpg",
    component: Blog_2,
    sharingUrl:
      siteUrl + "/blog/your-ultimate-online-sweepstakes-casino-experience",
    date: "2025-01-17",
  },
  {
    id: "3",
    slug: "how-to-play-online-casinos-for-free",
    image: "/assets/carousel/blog-three.jpg",

    metaTitle: "How to Play at an Online Casino For Free? ",
    title: "How to Play at an Online Casino For Free? ",
    content:
      "Let's look at the several options for playing free online casino games.What if you could do all of that without jeopardizing your own money? Read Now!",
    metaDescription:
      "Let's look at the several options for playing free online casino games.What if you could do all of that without jeopardizing your own money? Read Now!",
    canonical: siteUrl + "/blog/how-to-play-online-casinos-for-free",

    ogTitle: "How to Play at an Online Casino For Free? ",
    ogDescription:
      "Let's look at the several options for playing free online casino games.What if you could do all of that without jeopardizing your own money? Read Now!",
    ogImage: siteUrl + "/assets/carousel/blog-three.jpg",
    component: Blog_3,
    sharingUrl: siteUrl + "/blog/how-to-play-online-casinos-for-free",
    date: "2025-02-14",
  },
  {
    id: "4",
    slug: "how-to-play-the-fish-game-gambling-at-online-casinos",
    image: "/assets/carousel/blog-four.png",
    metaTitle: "How Do You Play Fish Game Gambling at Online Casinos?",
    title: "How Do You Play Fish Game Gambling at Online Casinos?",
    content:
      "How to play the fish game at online casinos. This guide covers everything you need to know to start and improve your chances of winning.",
    metaDescription:
      "How to play the fish game at online casinos. This guide covers everything you need to know to start and improve your chances of winning.",
    canonical:
      siteUrl + "/blog/how-to-play-the-fish-game-gambling-at-online-casinos",

    ogTitle: "How Do You Play Fish Game Gambling at Online Casinos?",
    ogDescription:
      "How to play the fish game at online casinos. This guide covers everything you need to know to start and improve your chances of winning.",
    ogImage: siteUrl + "/assets/carousel/blog-four.png",
    component: Blog_4,
    sharingUrl:
      siteUrl + "/blog/how-to-play-the-fish-game-gambling-at-online-casinos",
    date: "2025-03-11",
  },
  {
    id: "5",
    slug: "top-strategies-for-winning-at-online-casino-slots",
    image: "/assets/carousel/blog-five.png",
    metaTitle: "Top Strategies for Winning at Online Casino Slots",
    title: "Top Strategies for Winning at Online Casino Slots",
    content:
      "Let’s dive into some insider strategies to make your online slots casino experience not only more exciting but more rewarding! Read Top Tips!",
    metaDescription:
      "Let’s dive into some insider strategies to make your online slots casino experience not only more exciting but more rewarding! Read Top Tips!",
    canonical:
      siteUrl + "/blog/top-strategies-for-winning-at-online-casino-slots",

    ogTitle: "Top Strategies for Winning at Online Casino Slots",
    ogDescription:
      "Let’s dive into some insider strategies to make your online slots casino experience not only more exciting but more rewarding! Read Top Tips!",
    ogImage: siteUrl + "/assets/carousel/blog-five.png",
    component: Blog_5,
    sharingUrl:
      siteUrl + "/blog/top-strategies-for-winning-at-online-casino-slots",
    date: "2025-03-17",
  },
  {
    id: "6",
    slug: "skill-vs-luck-what-really-matters-in-fish-game-gambling",
    image: "/assets/carousel/blog-six.png",
    metaTitle: "Skill vs Luck: What Really Matters in Fish Game Gambling?",
    title: "Skill vs Luck: What Really Matters in Fish Game Gambling?",
    content:
      "Whether you’re a beginner or a seasoned player, this is your go-to guide to understanding the psychology and strategy of fish shooting games in the USA.",
    metaDescription:
      "Whether you’re a beginner or a seasoned player, this is your go-to guide to understanding the psychology and strategy of fish shooting games in the USA.",
    canonical:
      siteUrl + "/blog/skill-vs-luck-what-really-matters-in-fish-game-gambling",

    ogTitle: "Skill vs Luck: What Really Matters in Fish Game Gambling?",
    ogDescription:
      "Whether you’re a beginner or a seasoned player, this is your go-to guide to understanding the psychology and strategy of fish shooting games in the USA.",
    ogImage: siteUrl + "/assets/carousel/blog-six.png",
    component: Blog_6,
    sharingUrl:
      siteUrl + "/blog/skill-vs-luck-what-really-matters-in-fish-game-gambling",
    date: "2025-03-24",
  },
  {
    id: "7",
    slug: "top-most-played-games-on-fire-kirin-777-casino",
    image: "/assets/carousel/blog-seven.jpg",
    metaTitle: "What are some of the most played games on Fire Kirin 777",
    title: "What are some of the most played games on Fire Kirin 777",
    content:
      "Enjoy a variety of Firekirin casino games online. Some popular games are Buffalo 777, God of Fortune, Dragon Dynasty, and Curse of Pharaoh.",
    metaDescription:
      "Enjoy a variety of Firekirin casino games online. Some popular games are Buffalo 777, God of Fortune, Dragon Dynasty, and Curse of Pharaoh.",
    canonical: siteUrl + "/blog/top-most-played-games-on-fire-kirin-777-casino",

    ogTitle: "What are some of the most played games on Fire Kirin 777",
    ogDescription:
      "Enjoy a variety of Firekirin casino games online. Some popular games are Buffalo 777, God of Fortune, Dragon Dynasty, and Curse of Pharaoh.",
    ogImage: siteUrl + "/assets/carousel/blog-seven.jpg",
    component: Blog_7,
    sharingUrl:
      siteUrl + "/blog/top-most-played-games-on-fire-kirin-777-casino",
    date: "2025-04-03",
  },
];

let blogData = blogItems.sort((a, b) => new Date(b.date) - new Date(a.date));
export default blogData;
